import { defineStore } from 'pinia';
import { computed, markRaw, reactive, ref, shallowRef } from 'vue';
import { useRoute } from 'vue-router';
import getSymbolFromCurrency from 'currency-symbol-map';
import { v4 as uuidv4 } from 'uuid';
import { isBoolean, isEmpty, orderBy } from 'lodash-es';

import { setPunterPreferences } from '../api/punter-preferences';
import { getCursorOfferStats, getEvents } from '../api/distribution.js';
import { getBetList, getBetDetailsByExternalBetId } from '../api/bet-list';
import { getCashoutBetList, calculateCashout, executeCashout } from '../api/cashout';
import { getBetBuilderCalculations, getCustomBets } from '@/api/custom-bet';
import { constructClientIds, supportedOddFormats } from '../utils/helpers';

import { useBetslipStore } from './BetslipStore';
import useBetslip from '../composables/betslip/useBetslip';

import useBreakpointsCreator from '@/composables/general/useBreakpoints';
import { constructStartDate, formatNumber } from '../utils/helpers';

import OfferWorker from '@/services/offer-worker/index?worker';
import {
  subscribeEventsOnMetadataChanges,
  subscribeEventsOnOfferChanges,
  unsubscribeEventsFromOfferDistributionSocket,
} from '../services/sockets/offer-distribution';
import { getEventName } from '@/services/helpers';
import {
  checkEventFitmentInOffer,
  mapEventMarketOutcomes,
  mapMarkets,
  setupOffer,
  sortEvents,
} from '@/services/offer-adapter';
import { setItemToStorage } from '@/services/storage';
import { isMobile } from '../services/helpers';

const offerWorker = new OfferWorker();

let notificationTimeouts = {};
export const useStore = defineStore('store', () => {
  // Betslip store
  const betslipStore = useBetslipStore();
  const { calculatePayments } = useBetslip();

  // Router
  const route = useRoute();

  const isLive = computed(
    () => route.name === 'live' || window.location.pathname.includes('/live'),
  );

  const routeParams = computed(() => {
    const paramValues = {};

    const [urlParams] = window.location.href.split('?');
    const [, , , ...params] = urlParams.split('/');

    let routeParams = route?.params?.filters?.length ? route.params?.filters : params;

    for (const param of routeParams) {
      const [type, value] = param.split('_');

      switch (type) {
        //view
        case 'v':
          paramValues.view = value;
          break;
        //sport
        case 's':
          paramValues.sport = value;
          break;
        //category
        case 'c':
          paramValues.category = value;
          break;
        //tournament
        case 't':
          paramValues.tournament = value;
          break;
        // date
        case 'd':
          paramValues.time = value;
          break;
        // favourites
        case 'f':
          paramValues.favourites = true;
          break;
        // event
        case 'e':
          paramValues.event = value;
          break;
        // promoted-offer
        case 'po':
          paramValues.promotedOffer = value;
          break;
        // catalog
        case 'ctg':
          paramValues.catalog = value;
          break;
      }
    }

    return paramValues;
  });

  // Local config
  const config = ref({});
  function setConfig(data) {
    config.value = data;
  }

  // Translations
  const translations = shallowRef({});
  function setTranslations(data) {
    if (!data) return;
    translations.value = markRaw(data);
  }

  /**
   * Retrieves a translation for a given key and optionally interpolates values.
   * @param {string} key - The key of the translation to retrieve.
   * @param {Object.<string, string|number|boolean>} [interpolationValues=null] - An object containing key-value pairs of values to interpolate into the translation.
   * @returns {string} The translated string with optional interpolation.
   */
  function getTranslation(key, interpolationValues = null) {
    const translation = translations.value[key] ?? key;
    if (!interpolationValues) return translation;

    return translation.replace(/{{(.*?)}}/g, (_, key) => interpolationValues[key.trim()]);
  }

  // Punter
  const punterData = ref({});
  const punterLoggedIn = ref(false);

  // Punter Wallet
  /*
   * Note:
   * Punter Wallet is disabled by default, until we receive a Wallet update from Parent frame.
   * While wallet remains disabled, we'll ignore Punter Balance checks throughout the app.
   * */
  const punterWallet = ref({
    enabled: false,
    activeCurrencyBalance: 0,
  });
  function updatePunterWallet(data) {
    punterWallet.value.enabled = data?.enabled || false;
    punterWallet.value.activeCurrencyBalance = data?.activeCurrencyBalance || 0;
  }

  // Offer filters - @ToDo: Rework
  const filterCursorId = ref(null);
  const filterCategories = ref({});
  const filterSports = ref({});
  const filterTournaments = ref({});

  // expands
  const expandedSport = ref(null);
  const expandedCategory = ref(null);
  const expandedPromoOffer = ref(null);

  function resetFilterData() {
    filterCursorId.value = null;
    filterCategories.value = {};
    filterSports.value = {};
    filterTournaments.value = {};
  }

  function setFilterData(data) {
    filterCursorId.value = data?.cursorId ?? filterCursorId.value;

    const updateData = (_data, list, numberOfEvents = {}) => {
      if (!_data || !_data.length) return;

      _data.forEach((entry) => {
        list.value[entry.id] = {
          ...list.value[entry.id],
          ...entry,
          numberOfEvents: numberOfEvents?.[entry.id] ?? list.value?.[entry.id]?.numberOfEvents ?? 0,
        };
      });
    };

    const updateSports = () => {
      if (data.sports?.length) {
        updateData(data.sports, filterSports, data.sportNumberOfEvents);
        return;
      }

      const filteredSportsKeys = Object.keys(filterSports.value);
      const filterSportsUpdated = filteredSportsKeys.reduce((acc, key) => {
        acc[key] = {
          ...filterSports.value[key],
          numberOfEvents: data?.sportNumberOfEvents?.[key] ?? 0,
        };
        return acc;
      }, {});

      filterSports.value = filterSportsUpdated;
      if (
        selectedFiltersExternal.value.sport &&
        !filterSportsUpdated[selectedFiltersExternal.value.sport?.id]?.numberOfEvents
      ) {
        setSelectedFiltersExternal({
          ...selectedFiltersExternal.value,
          ...(isLive.value
            ? { id: 'all', sport: null }
            : { id: null, sport: Object.values(filterSportsUpdated)[0] }),
        });
      }
    };

    const updateCategories = () => {
      if (data.categories?.length) {
        updateData(data.categories, filterCategories, data.categoryNumberOfEvents);
        return;
      }

      const filteredCategoriesKeys = Object.keys(filterCategories.value);

      filterCategories.value = filteredCategoriesKeys.reduce((acc, key) => {
        acc[key] = {
          ...filterCategories.value[key],
          numberOfEvents: data.categoryNumberOfEvents?.[key] ?? 0,
        };
        return acc;
      }, {});
    };

    const updateTournaments = () => {
      if (data.tournaments?.length) {
        updateData(data.tournaments, filterTournaments, data.tournamentNumberOfEvents);
        return;
      }

      const filteredTournamentsKeys = Object.keys(filterTournaments.value);

      filterTournaments.value = filteredTournamentsKeys.reduce((acc, key) => {
        acc[key] = {
          ...filterTournaments.value[key],
          numberOfEvents: data.tournamentNumberOfEvents?.[key] ?? 0,
        };
        return acc;
      }, {});
    };

    if (data.sports) updateSports();
    if (data.categories) updateCategories();
    if (data.tournaments) updateTournaments();

    // TODO: Add automatic fallback for filter selection when tournament or category is missing events (not visible in sidebar anymore)
    //       Check do we need to reset selection the same way as we do it for sports or we select parent if it has events, if no
    //       then select grandparent. If there is no grandparent select first sport or All option for live
  }

  // filters for promo data
  const promoFilterCursorId = ref(null);
  const promoFilterOffers = ref({});
  const promoFilterCatalogs = ref({});

  function resetPromoOfferFilterData() {
    promoFilterCursorId.value = null;
    promoFilterOffers.value = {};
    promoFilterCatalogs.value = {};
  }

  function setPromoOfferFilterData(data) {
    promoFilterCursorId.value = data?.cursorId ?? promoFilterCursorId.value;

    const updateData = (_data, list, numberOfEvents = {}) => {
      if (!_data || !_data.length) return;

      _data.forEach((entry) => {
        list.value[entry.id] = {
          ...list.value[entry.id],
          ...entry,
          numberOfEvents: numberOfEvents?.[entry.id] ?? list.value?.[entry.id]?.numberOfEvents ?? 0,
        };
      });
    };

    const updateOffers = () => {
      if (data.promotedOffers?.length) {
        updateData(data.promotedOffers, promoFilterOffers, data.promotedOfferNumberOfEvents);
        return;
      }

      promoFilterOffers.value = Object.keys(promoFilterOffers.value).reduce((acc, key) => {
        acc[key] = {
          ...promoFilterOffers.value[key],
          numberOfEvents: data?.promotedOfferNumberOfEvents?.[key] ?? 0,
        };
        return acc;
      }, {});
    };

    const updateCatalogs = () => {
      if (data.promotedOfferCatalogs?.length) {
        updateData(
          data.promotedOfferCatalogs,
          promoFilterCatalogs,
          data.promotedOfferCatalogNumberOfEvents,
        );
        return;
      }

      promoFilterCatalogs.value = Object.keys(promoFilterCatalogs.value).reduce((acc, key) => {
        acc[key] = {
          ...promoFilterCatalogs.value[key],
          numberOfEvents: data.promotedOfferCatalogNumberOfEvents?.[key] ?? 0,
        };
        return acc;
      }, {});
    };

    if (data.promotedOffers) updateOffers();
    if (data.promotedOfferCatalogs) updateCatalogs();
  }

  /**
   * @name: marketSpecifierTypes
   * @see: useExpressionLanguage.js
   * Data needed for AIO Expresion language.
   * unlike other offer components, it's not susceptible to changes.
   */
  // const marketSpecifierTypes = shallowRef([]);

  // Loading
  const loading = ref(true);
  function setLoading(value) {
    loading.value = value;
  }

  /** @name: TimeFilters */
  /** @todo: refactor and move from store */
  const timeFilters = computed(() => {
    const sixDaysFromToday = Array.from({ length: 7 }, (_, index) =>
      dayjs().add(index, 'day').startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    ).reduce((dayArr, day) => {
      if (dayjs(day).isToday()) return dayArr;

      return [
        ...dayArr,
        {
          id: dayjs(day).format('dddd').toLowerCase(),
          date: day,
          name: getTranslation(dayjs(day).locale('en').format('dddd').toLowerCase()),
        },
      ];
    }, []);

    return [
      { id: 'all', date: 'all', name: getTranslation('general_all') },
      { id: 'today', date: constructStartDate(true), name: getTranslation('general_today') },
      {
        id: 'upcoming',
        date: constructStartDate(false),
        endDate: dayjs().add(3, 'hour'),
        name: getTranslation('coming_soon'),
      },
      { id: 'live', name: getTranslation('live_betting'), hideOnDesktop: true },
      ...sixDaysFromToday,
    ].reduce((acc, n) => {
      acc[n.id] = n;
      return acc;
    }, {});
  });

  // App. filters
  const selectedFiltersExternal = ref({
    sport: routeParams.value.sport ?? null,
    category: routeParams.value.category ?? null,
    tournament: routeParams.value.tournament ?? null,
    time: routeParams.value.time ?? Object.keys(timeFilters.value)[0],
    sortBy: routeParams.value.sortBy ?? 'byTournament',
    favourites: routeParams.value.favourites ?? null,
    id: isLive.value && !routeParams.value.sport && !routeParams.value.favourites ? 'all' : null,
    promotedOffer: routeParams.value.promotedOffer ?? null,
    catalog: routeParams.value.catalog ?? null,
  });

  /**
   * @function setSelectedFiltersExternal
   * @param {{
   *  sport: string | number
   *  category: string | number
   *  tournament: string | number
   *  time: string
   *  sortBy: string
   *  favourites: boolean
   *  id: string | number
   * }} filters
   * @description Updates app. wide filters.
   */
  function setSelectedFiltersExternal(filters) {
    if (filters?.time === 'live' && !routeParams.value.view) {
      setSelectedView('live');
      return;
    }

    selectedFiltersExternal.value = {
      ...filters,
      time: filters.time ?? Object.keys(timeFilters.value)[0],
      id: filters.id ?? '',
    };
  }

  // Search
  const searchTerm = ref('');
  const searchToggled = ref(false);
  function setSearchTerm(value) {
    searchTerm.value = value?.length === 0 ? '' : value;
  }

  // Fav. events
  const numberOfFavouriteEvents = ref(0);
  function setNumberOfFavouriteEvents(sportNumberOfEvents = []) {
    numberOfFavouriteEvents.value = sportNumberOfEvents.reduce((acc, n) => (acc += n), 0);
  }

  const eventviewMetadataRef = reactive(new Map());

  function setEventviewMetadata({ sportId, markets, outcomes, marketGroups }) {
    if (eventviewMetadataRef.size > 3)
      eventviewMetadataRef.delete(eventviewMetadataRef.keys().next().value);

    eventviewMetadataRef.set(sportId, { markets, marketGroups, outcomes });
  }

  const selectedMarketGroup = ref('all');
  const selectedMoreLabelMarketGroup = ref('');

  const eventviewId = ref(+routeParams.value.event ?? null);
  const eventviewEvent = ref({});
  const eventviewMarkets = ref(new Map());
  const eventviewOutcomes = ref(new Map());
  const eventviewMarketGroups = ref(new Map());

  const eventviewTournamentSwapEventsList = ref([]);
  const eventviewMarketToFocus = ref(null);

  // Selected view
  const selectedView = ref(
    isLive.value && !routeParams.value.view ? 'live' : (routeParams.value.view ?? 'sport'),
  );
  const selectedSportInSearch = ref(null);
  function setSelectedView(view) {
    selectedView.value = view;

    switch (view) {
      case 'search':
        searchTerm.value = '';
        selectedSportInSearch.value = null;
        break;
    }
  }

  const lastBets = ref({});
  const loadBetList = async () => {
    lastBets.value = {};

    const response = await getBetList({
      page: 1,
      size: 20,
    });
    if (!response?.data) return;

    if (!response?.data) return;

    response.data.forEach((betslip, index) => {
      const groupColorIndex = betslip.bets.length > 1 ? (index % 2 === 0 ? 1 : 2) : null;

      betslip.bets.forEach((bet) => (lastBets.value[bet.id] = { ...bet, groupColorIndex }));
    });
  };

  const cashoutBets = ref({});
  const cashoutCalculationData = ref([]);
  const fetchCashoutBets = async () => {
    try {
      cashoutBets.value = {};
      cashoutCalculationData.value = [];

      const response = await getCashoutBetList();

      if (!response) return;

      response.forEach(({ betProfile }) => {
        const betData = {
          ...betProfile.bet,
          betProfileId: betProfile.id,
          resolutionStatuses: [{ value: 'OPEN', betState: 'OPEN' }],
          aggregatedStatus: 'OPEN',
        };

        cashoutBets.value[betData.id] = betData;
        cashoutCalculationData.value.push({
          betProfile: {
            id: betData.betProfileId,
          },
        });
      });
    } catch (error) {
      console.log(error);
    }
  };

  const forceCashoutCalculation = () => {
    calculateCashout(cashoutCalculationData.value);
  };

  const addPlacedBetsToBetList = (bets) => {
    bets.forEach((bet) => {
      const formattedBet = {
        ...bet,
        new: true,
        id: bet.betId,
        phase: 'PLACED',
        resolutionStatuses: [{ value: 'OPEN', betState: 'OPEN' }],
        aggregatedStatus: 'OPEN',
        payin: {
          stake: {
            type: 'TOTAL',
            amount: bet.payin.totalStake,
          },
        },
      };

      lastBets.value = {
        [bet.betId]: formattedBet,
        ...lastBets.value,
      };
    });
  };

  const punterBetsLoading = ref(false);
  const loadPunterBets = async () => {
    punterBetsLoading.value = true;
    await Promise.all([loadBetList(), fetchCashoutBets()]);
    forceCashoutCalculation();
    punterBetsLoading.value = false;
  };

  const cashOutBet = async ({ betProfileId, cashout, id, currency }) => {
    try {
      if (!cashout.allowed) return;

      cashout.processing = true;

      const response = await executeCashout(betProfileId, {
        bet: {
          winnings: cashout.winnings,
        },
      });

      if (!response) {
        cashout.processing = false;
        addNotification({
          timeout: 2000,
          text: getTranslation('cashout_error'),
          type: 'error',
        });
        return;
      }

      addNotification({
        timeout: 2000,
        text: `${formatNumber(response.total, currency)} ${getTranslation('cashout_success', { value: '' })}`,
        type: 'success',
      });

      updateCashoutedBetData(id, response);
    } catch (error) {
      addNotification({
        timeout: 2000,
        text: getTranslation('cashout_error'),
        type: 'error',
      });
    }
  };

  const updateCashoutedBetData = (id, cashoutWinnings) => {
    delete cashoutBets.value[id];

    if (lastBets.value[id]) {
      lastBets.value[id].cashout = null;
      lastBets.value[id].aggregatedStatus = 'CASHOUTED';
      lastBets.value[id].winnings.push({ type: 'SETTLE', ...cashoutWinnings });
    }

    if (betDetails.value?.id === id) {
      betDetails.value.cashout = null;
      betDetails.value.aggregatedStatus = 'CASHOUTED';
      betDetails.value.winnings.push({ type: 'SETTLE', ...cashoutWinnings });
    }
  };

  const betDetails = ref(null);
  const betDetailsSelections = ref([]);

  const betPreviewDisplayed = ref(false);
  async function toggleBetPreview(value, bet = {}) {
    const showPreview = isBoolean(value) ? value : !betPreviewDisplayed.value;
    betPreviewDisplayed.value = showPreview;
    betDetails.value = showPreview ? bet : null;
    betDetailsSelections.value = showPreview ? mapBetDetailsSelections(bet.selections) : null;
  }

  function mapBetDetailsSelections(selections) {
    if (!selections.length) return [];

    const orderedSelections = orderBy(
      selections,
      ['banker', (s) => s.event.startAt],
      ['desc', 'asc'],
    );

    return new Map(orderedSelections.map((selection) => [selection.event.syntheticId, selection]));
  }

  function setBetDetails(data) {
    betDetails.value = data;
  }

  async function getAvailableEvents(events) {
    try {
      const params = {
        clientIds: constructClientIds(),
        waitForEventsToBecomeAvailable: false,
        ...(config.value?.supplemental?.list
          ? { supplementalNameTypeIds: config.value.supplemental.list }
          : {}),
      };
      const response = await getEvents(events, '1,2', params);

      return response.data?.events ?? [];
    } catch (error) {
      console.log(`We've encountered error loading available events.`, error);
      return [];
    }
  }

  async function openDynamicBetDetails({ id, cashout, betProfileId }) {
    const response = await getBetDetailsByExternalBetId(id);

    if (response?.bet) toggleBetPreview(true, { ...response.bet, cashout, betProfileId });
  }

  function updateCashoutBets(data) {
    if (isEmpty(lastBets.value) && isEmpty(cashoutBets.value)) return;

    const { id, bet } = data.betProfile;

    const cashout = { allowed: data.allowed, winnings: bet.winnings };

    const foundBetInList = lastBets.value[bet.id];
    const foundBetInCashoutList = cashoutBets.value[bet.id];

    const listOfCodesForForbiddenCashout = new Set([1002, 1000, 1005]);
    const hideCashout = data?.reasons?.some((reason) =>
      listOfCodesForForbiddenCashout.has(reason.code),
    );

    if (hideCashout) {
      if (foundBetInList) lastBets.value[bet.id].cashout = null;
      if (foundBetInCashoutList) delete cashoutBets.value[bet.id];
      return;
    }

    if (foundBetInList?.aggregatedStatus === 'CASHOUTED') return;

    if (foundBetInList) {
      lastBets.value[bet.id] = {
        ...foundBetInList,
        cashout,
        betProfileId: id,
      };
    }

    if (foundBetInCashoutList) {
      cashoutBets.value[bet.id] = {
        ...foundBetInCashoutList,
        cashout,
        betProfileId: id,
      };
    }

    if (foundBetInList && !foundBetInCashoutList) {
      cashoutBets.value = {
        [bet.id]: {
          ...foundBetInList,
          cashout,
          betProfileId: id,
        },
        ...cashoutBets.value,
      };
    }

    if (betDetails.value?.id === bet.id && betDetails.value.aggregatedStatus !== 'CASHOUTED') {
      betDetails.value = {
        ...betDetails.value,
        cashout,
        betProfileId: id,
      };
    }
  }

  // Betslip bar
  const betslipBarFixedToBottom = ref(false);
  function fixBetslipBarToBottom(value = false) {
    betslipBarFixedToBottom.value = value;
  }

  // Active ticket tab
  const activeTicketTab = ref('betslip');
  function setActiveTicketTab(tab) {
    activeTicketTab.value = tab;
  }

  // Notifications
  const notifications = ref(new Map());
  function removeNotification(id) {
    delete notifications.value.delete(id);
  }
  /**
   * Adds or updates a notification in the store.
   * @param {Object} notification - The notification object to be added or updated.
   * @param {string} [notification.id] - The ID of the notification. Optional if a new notification is being added; required if updating an existing notification.
   * @param {number} [notification.timeout] - The timeout for the notification.
   * @param {string} notification.text - The text of the notification.
   * @param {string} notification.type - The type of the notification (e.g., 'warning', 'info').
   * @param {string} notification.actionIcon - The icon for the action associated with the notification.
   * @param {string} notification.actionText - The text for the action associated with the notification.
   * @param {boolean} notification.closeAble - Whether the notification can be closed.
   * @param {Function} notification.callback - The callback function to be executed when the action associated with the notification is triggered.
   */
  function addNotification(notification) {
    const notificationUuid = notification?.id || uuidv4();

    if (notificationTimeouts[notificationUuid]) {
      clearTimeout(notificationTimeouts[notificationUuid]);
      delete notificationTimeouts[notificationUuid];
    }

    notifications.value.set(notificationUuid, { ...notification, id: notificationUuid });

    if (notification?.timeout) {
      notificationTimeouts[notificationUuid] = setTimeout(() => {
        clearTimeout(notificationTimeouts[notificationUuid]);
        removeNotification(notificationUuid);
      }, notification.timeout);
    }
  }

  // Number of markets to display
  // @ToDo: Move to composable
  const { greaterOrEqualToXxl, betweenLgAndXxl, betweenMdAndLg, betweenSmAndMd, betweenXsAndSm } =
    useBreakpointsCreator();

  const displayedMarketsNumber = computed(() => {
    if (greaterOrEqualToXxl.value) return 3;
    if (betweenLgAndXxl.value) return 2;
    if (betweenMdAndLg.value) return 2;
    if (betweenSmAndMd.value) return 1;
    if (betweenXsAndSm.value) return 1;

    return 1;
  });

  const promotedEvents = ref(new Map());
  const promotedEventsList = ref([]);
  const promotedMarketsList = ref([]);
  const promotedOutcomesList = ref([]);

  // Selected market
  const selectedMarket = ref({});

  // Currency
  const currency = ref(null);
  const currencySymbol = ref(getSymbolFromCurrency(window.currency) ?? '');

  // Punter preferences
  const punterPreferences = ref({});

  async function updatePunterPreferences({ key, value, data }, notification = false) {
    if (!punterPreferences.value) punterPreferences.value = {};

    if (!data) punterPreferences.value[key] = value;
    else punterPreferences.value = data;

    let successfullySavedPreferences;
    if (!window.punter?.id) {
      setItemToStorage('punterPreferences', punterPreferences.value);
      successfullySavedPreferences = true;
    } else {
      const response = await setPunterPreferences(window.punter?.id, punterPreferences.value);
      successfullySavedPreferences = response?.status === 200;
    }

    if (!notification) return;

    const status = successfullySavedPreferences ? 'success' : 'error';
    addNotification({
      timeout: 2000,
      text: getTranslation(`preferences_updated_${status}`),
      type: status,
    });
  }

  // Odd format
  const oddFormat = computed(
    () =>
      punterPreferences.value?.oddFormat ??
      (supportedOddFormats[window.oddsType] ? window.oddsType : config.value?.defaultOddsFormat),
  );
  const offerView = computed(() => {
    return (
      (isMobile
        ? (punterPreferences.value?.mobileOfferView ?? config.value?.defaultMobileOfferView)
        : (punterPreferences.value?.desktopOfferView ?? config.value?.defaultDesktopOfferView)) ??
      'standard'
    );
  });

  const offerGridLayout = computed(() => {
    return isMobile
      ? (punterPreferences.value.offerGridMobileLayout ??
          availableOfferViews.value.get(config.value.defaultMobileOfferView)?.[0] ??
          'vertical')
      : (punterPreferences.value.offerGridDesktopLayout ??
          availableOfferViews.value.get(config.value.defaultDesktopOfferView)?.[0] ??
          'horizontal');
  });

  const punterSelectedTheme = computed(() => punterPreferences.value?.theme);

  const availableOddFormats = computed(() => [
    { id: 'decimal', name: getTranslation('decimal_odds') },
    { id: 'american', name: getTranslation('american_odds') },
    { id: 'fractional', name: getTranslation('fractional_odds') },
    { id: 'hongkong', name: getTranslation('hongkong_odds') },
    { id: 'indonesian', name: getTranslation('indonesian_odds') },
    { id: 'malaysian', name: getTranslation('malaysian_odds') },
  ]);

  // Number of live events
  const numberOfLiveEvents = ref(0);
  const liveEventsStatsCursor = ref(null);

  function setNumberOfLiveEvents(sportNumberOfEvents) {
    numberOfLiveEvents.value = Object.values(sportNumberOfEvents).reduce(
      (acc, numberOfEventsPerSport) => acc + numberOfEventsPerSport,
      0,
    );
  }

  function createLiveEventsCountPeriodicUpdate() {
    async function updateNumberOfLiveEvents() {
      try {
        const response = await getCursorOfferStats(
          {
            clientIds: constructClientIds(),
            ...(config.value?.supplemental?.list
              ? { supplementalNameTypeIds: config.value.supplemental.list }
              : {}),
          },
          '2',
          liveEventsStatsCursor.value,
        );
        liveEventsStatsCursor.value = response.data.cursorId;
        setNumberOfLiveEvents(response.data.sportNumberOfEvents);
      } catch (error) {
        console.log(error);
      }
    }

    updateNumberOfLiveEvents();
    setInterval(async () => updateNumberOfLiveEvents(), 30000);
  }

  async function rebet({ bet, events, systems, totalCombinations, allEventsAvailable }) {
    try {
      const ticketType = bet?.type?.toLowerCase() || 'combo';
      const stake = bet.payin.total.toFixed(2);

      betslipStore.clearBetslip();
      betslipStore.selectTicketType(ticketType);
      betslipStore.updateStake(stake);

      reduceRebetSelections(bet.selections, events);
      calculatePayments();

      if (ticketType === 'system' && allEventsAvailable) {
        systems.value.forEach((system) => {
          betslipStore.updateSystem({
            id: system.parlays,
            stake: parseFloat(stake / systems.value.length).toFixed(2),
            selected: true,
            combinations: totalCombinations.value,
          });
        });
        betslipStore.checkForSystemCombinations();
      }

      return true;
    } catch {
      displayRebetUnavailableMessage();
    }
  }

  async function calculateBetBuilderOdds(selection, customBetsSelections) {
    const betBuilderData = [...selection.markets].map(([, { marketId, outcomeId }]) => ({
      eventMarketId: marketId,
      eventMarketOutcomeId: outcomeId,
      feed: customBetsSelections?.[marketId]?.outcomes[outcomeId]?.feed,
    }));
    const bbCalculations = await getBetBuilderCalculations(selection.eventId, betBuilderData);
    return bbCalculations?.odds * 10000;
  }

  const customBetsLoading = ref(false);
  async function loadCustomBets(id = null) {
    const eventId = id ?? eventviewId.value;
    if (betBuilderSelections.value.has(eventId)) return true;

    customBetsLoading.value = true;
    const response = await getCustomBets(eventId);
    sendOfferWorkerMessage('set-event-bet-builder-selections', {
      eventId,
      selections: response?.selections ?? [],
    });
    customBetsLoading.value = false;

    return true;
  }

  async function reduceRebetSelections(rawSelections, events) {
    const selectionsList = [];
    const selectionsData = {};

    for (const selection of rawSelections) {
      const event = events.find((event) => event.id === +selection.event.syntheticId);
      if (!event) continue;

      for (const {
        syntheticId: outcomeSynteticId,
        eventMarket: market,
        name: outcomeName,
      } of selection.eventMarketOutcomes) {
        const eventMarket = event.markets.find(({ id }) => id === +market.syntheticId);
        if (!eventMarket) continue;

        const eventMarketOutcome = eventMarket.outcomes.find(({ id }) => id === +outcomeSynteticId);
        if (!eventMarketOutcome) continue;

        const constructSelectionIndex = () => {
          if (selection.type === 'CUSTOM') return `${event?.id}/bet_builder`;
          return `${event?.id}/${eventMarket?.id}/${eventMarketOutcome?.id}`;
        };

        const selectionIdx = constructSelectionIndex();

        if (!selectionsList.includes(selectionIdx)) {
          selectionsList.push(selectionIdx);
          selectionsData[selectionIdx] = {
            active: true,
            banker: selection.banker,
            id: selectionIdx,
            competitors: orderBy(event.competitors, 'ordinal').map((competitor) =>
              getEventName(competitor),
            ),
            eventCompetitors: [],
            type: selection.type === 'CUSTOM' ? 'bet_builder' : 'regular',
            eventId: event.id,
            startsAt: event.startsAt,
            playStatus: event.playStatus,
            sportId: +selection.sport.syntheticId,
            metadata: event?.metadata ?? null,
            categoryId: +selection.category.syntheticId,
            tournamentId: +selection.tournament.syntheticId,
            game: event.playStatus === 2 ? 'LIVE' : 'PREMATCH',
            markets: new Map(),
            previousOdds: null,
            odds: eventMarketOutcome.odds,
            limits: {},
          };
        }
        selectionsData[selectionIdx].markets.set(`${eventMarket.id}/${eventMarketOutcome.id}`, {
          active: true,
          marketId: eventMarket.id,
          marketName: market.name,
          metaMarketId: eventMarket.marketId,
          outcomeId: eventMarketOutcome.id,
          metaOutcomeId: eventMarketOutcome.outcomeId,
          odds: eventMarketOutcome.odds,
          outcomeName,
        });

        if (selection.type === 'CUSTOM') {
          betslipStore.selectTicketType('combo');

          const customBets = await getCustomBets(event.id);
          sendOfferWorkerMessage('set-event-bet-builder-selections', {
            eventId: event.id,
            selections: customBets?.selections ?? [],
          });

          selectionsData[selectionIdx].odds = await calculateBetBuilderOdds(
            selectionsData[selectionIdx],
            customBets.selections,
          );
        }
      }
    }

    betslipStore.selectionsList = selectionsList;
    betslipStore.selectionsData = selectionsData;
  }

  function subscribeBetEvents(events) {
    const eventList = [];
    const inPlayEvents = [];
    const eventsToSubscribeOnOfferChanges = [];

    events.forEach(({ id, playStatus, version }) => {
      eventList.push(id);
      eventsToSubscribeOnOfferChanges.push({ id, version });
      if (playStatus === 2) inPlayEvents.push(id);
    });

    unsubscribeEventsFromOfferDistributionSocket(eventList);

    subscribeEventsOnOfferChanges(eventsToSubscribeOnOfferChanges);
    if (inPlayEvents.length) subscribeEventsOnMetadataChanges(inPlayEvents);
  }

  function displayRebetUnavailableMessage() {
    addNotification({
      timeout: 2000,
      text: getTranslation('rebet_unavailable'),
      type: 'error',
    });
  }

  // Offer
  const cursorId = ref(null);
  const sports = ref(new Map());
  const sportsList = ref([]);
  const categories = ref(new Map());
  const tournaments = ref(new Map());
  const tournamentRounds = ref(new Map());
  const markets = ref(new Map());
  const marketsPerSport = ref(null);
  const outcomes = ref(new Map());
  const events = ref(new Map());
  const eventsList = ref(null);
  const promoOffer = ref(new Map());
  const promoOfferList = ref([]);
  const catalogs = ref(new Map());
  const catalogList = ref([]);

  function resetCompleteOfferState() {
    cursorId.value = null;
    sports.value.clear();
    sportsList.value = [];
    categories.value.clear();
    tournaments.value.clear();
    tournamentRounds.value.clear();
    markets.value.clear();
    marketsPerSport.value = null;
    outcomes.value.clear();
    events.value = new Map();
    eventsList.value = null;
    promoOffer.value.clear();
    promoOfferList.value = [];
    catalogs.value.clear();
    catalogList.value = [];

    promotedEvents.value.clear();
    promotedEventsList.value = [];
    promotedMarketsList.value = [];

    promotedOutcomesList.value = [];

    selectedMarket.value = {};
  }

  function resetOffer() {
    const { selectionsByEvent } = useBetslipStore();

    const eventsToUnsubscribe = eventsList.value?.filter((eventId) => {
      return (
        !promotedEventsList.value?.includes(eventId) &&
        !selectionsByEvent?.includes(`${eventId}`) &&
        eventId !== eventviewId.value
      );
    });
    unsubscribeEventsFromOfferDistributionSocket(eventsToUnsubscribe);
    eventsList.value = [];

    const marketsTemp = new Map();
    for (const id of promotedMarketsList.value ?? []) {
      marketsTemp.set(id, markets.value.get(id));
    }

    const outcomesTemp = new Map();
    for (const id of promotedOutcomesList.value ?? []) {
      outcomesTemp.set(id, outcomes.value.get(id));
    }

    markets.value = marketsTemp;
    outcomes.value = outcomesTemp;
  }

  function updateOfferEntity(entity, list) {
    if (!list?.size) return;

    for (const [id, item] of list) {
      typeof entity === 'string' ? [entity].value.set(id, item) : entity.value?.set(id, item);
    }
  }

  function updateEventMetadata(rawEvents, updateType) {
    if (updateType === 2) {
      for (const { event } of rawEvents) {
        const collections = [
          events.value?.get(event.id),
          promotedEvents.value?.get(event.id),
          eventviewId.value === event.id && eventviewEvent.value,
        ].filter(Boolean);

        for (const collection of collections) {
          for (const market of event.markets) {
            const inStateEventMarket = collection.markets?.get(market.id) ?? {
              status: 0,
              outcomes: new Map(),
            };

            for (const outcome of market.outcomes) {
              const inStateEventMarketOutcome = inStateEventMarket.outcomes.get(outcome.id) ?? {
                status: 0,
              };

              inStateEventMarket.outcomes.set(outcome.id, {
                ...inStateEventMarketOutcome,
                ...outcome,
              });
            }

            inStateEventMarket.numberOfOutcomes = (
              eventviewMarkets.value?.get(market.id) || markets.value?.get(market.id)
            )?.outcomesList?.size;

            collection.markets.set(market.id, {
              ...inStateEventMarket,
              ...market,
              outcomes: inStateEventMarket.outcomes,
            });
          }
        }
      }
      return;
    }

    for (const e of rawEvents) {
      const collections = [
        events.value.get(e.id),
        promotedEvents.value.get(e.id),
        eventviewId.value === e.id && eventviewEvent.value,
      ].filter(Boolean);

      for (const collection of collections) {
        const markets = new Map();

        for (const m of e.markets) {
          const inStateMarket =
            eventviewEvent.value?.markets?.get(m.id) ?? collection?.markets?.get(m.id);

          const outcomes = new Map();
          for (const o of m.outcomes) {
            const inStateOutcome = inStateMarket?.outcomes?.get(o.id) ?? { status: 0 };
            outcomes.set(o.id, { ...inStateOutcome, ...o });
          }

          markets.set(m.id, {
            ...inStateMarket,
            ...m,
            outcomes,
            numberOfOutcomes: (eventviewMarkets.value?.get(m.id) || markets.value?.get(m.id))
              ?.outcomesList?.size,
          });
        }

        collection.markets = markets;
      }
    }
  }

  function unsubscribeEventsFromEventchanges() {
    const { selectionsByEvent } = useBetslipStore();

    const eventsToUnsubscribe = eventsList.value?.filter((eventId) => {
      return (
        !promotedEventsList.value?.includes(eventId) &&
        !selectionsByEvent?.includes(`${eventId}`) &&
        eventId !== eventviewId.value
      );
    });

    unsubscribeEventsFromOfferDistributionSocket(eventsToUnsubscribe);
  }

  // Bet builder
  const betBuilderSelections = ref(new Map());

  function resetBetBuilderSelections(eventId) {
    for (const [selectionId, selection] of betBuilderSelections.value?.get(eventId) ?? [])
      for (const [outcomeId] of selection.outcomes) {
        betBuilderSelections.value.get(eventId).get(selectionId).outcomes.get(outcomeId).available =
          true;
      }
  }

  function getEventSelectionsForCalculation(eventId, selectionIdx) {
    return [...(betslipStore.selectionsData[selectionIdx]?.markets ?? [])].reduce(
      (acc, [, { marketId, outcomeId, active }]) => {
        if (active)
          acc.push({
            eventMarketId: marketId,
            eventMarketOutcomeId: outcomeId,
            feed: betBuilderSelections.value?.get(eventId)?.get(marketId)?.outcomes?.get(outcomeId)
              ?.feed,
          });
        return acc;
      },
      [],
    );
  }

  // Offer Worker
  function sendOfferWorkerMessage(message, data) {
    offerWorker.postMessage(JSON.stringify({ message, data }));
  }

  function useOfferAdapter(message, rawData, cb = () => {}) {
    if (message === 'INITIAL_OFFER_SETUP') {
      const data = setupOffer(rawData, isLive.value);

      unsubscribeEventsFromEventchanges();

      cursorId.value = data.cursorId;

      updateOfferEntity(sports, data.sports);
      updateOfferEntity(categories, data.categories);
      updateOfferEntity(tournaments, data.tournaments);
      updateOfferEntity(tournamentRounds, data.tournamentRounds);
      updateOfferEntity(markets, data.markets);
      updateOfferEntity(outcomes, data.outcomes);

      sportsList.value = data.sportsList;
      marketsPerSport.value = data.marketsPerSport;

      if (data.promoOffers) {
        updateOfferEntity(promoOffer, data.promoOffers);
        promoOfferList.value = data.promoOfferList;
      }

      if (data.catalogs) {
        updateOfferEntity(catalogs, data.catalogs);
        catalogList.value = data.catalogList;
      }

      events.value = data.events;
      eventsList.value = sortEvents(
        data.eventsList,
        events.value,
        sports.value,
        categories.value,
        tournaments.value,
        tournamentRounds.value,
        isLive.value,
        promoOffer.value,
        catalogs.value,
      );

      subscribeEventsOnMetadataChanges(
        isLive.value
          ? data.eventsList
          : data.eventsList?.filter((id) => data.events.get(id).playStatus === 2),
      );
      subscribeEventsOnOfferChanges(data.eventsToSubscribeOnOfferChanges);

      setLoading(false);
    }

    if (message === 'VAIX_OFFER_SETUP') {
      const data = setupOffer(rawData, isLive.value);

      updateOfferEntity(sports, data.sports);
      updateOfferEntity(categories, data.categories);
      updateOfferEntity(tournaments, data.tournaments);
      updateOfferEntity(tournamentRounds, data.tournamentRounds);
      updateOfferEntity(markets, data.markets);
      updateOfferEntity(outcomes, data.outcomes);

      updateOfferEntity(promotedEvents, data.events);
      promotedEventsList.value = data.eventsList;
      promotedMarketsList.value = data.marketsList;
      promotedOutcomesList.value = data.outcomesList;

      subscribeEventsOnMetadataChanges(
        isLive.value
          ? data.eventsList
          : data.eventsList?.filter((id) => data.events.get(id)?.playStatus === 2),
      );
      subscribeEventsOnOfferChanges(data.eventsToSubscribeOnOfferChanges);
    }

    if (message === 'EVENTVIEW_SETUP') {
      const data = setupOffer(rawData, isLive.value);

      updateOfferEntity(sports, data.sports);
      updateOfferEntity(categories, data.categories);
      updateOfferEntity(tournaments, data.tournaments);

      const eventData = data.events.values().next().value;
      if (data.markets?.size)
        setEventviewMetadata({
          sportId: eventData.sportId,
          markets: data.markets,
          outcomes: data.outcomes,
          marketGroups: data.marketGroups,
        });

      const metadataPerSport = eventviewMetadataRef.get(eventData.sportId);
      if (metadataPerSport) {
        eventviewMarkets.value = metadataPerSport?.markets ?? new Map();
        eventviewOutcomes.value = metadataPerSport?.outcomes ?? new Map();
        eventviewMarketGroups.value = metadataPerSport?.marketGroups ?? new Map();
      }

      const betslipSelectionId = betslipStore.selectionsList.find((id) => {
        const [eventId] = id.split('/');
        return +eventId === eventData.id;
      });

      eventviewEvent.value = {
        ...eventData,
        metadata:
          events.value.get(eventData.id)?.metadata ??
          promotedEvents.value.get(eventData.id)?.metadata ??
          betslipStore.selectionsData?.[betslipSelectionId]?.metadata ??
          null,
      };

      if (eventviewEvent.value.playStatus === 2 || isLive.value)
        subscribeEventsOnMetadataChanges(data.eventsList);
      subscribeEventsOnOfferChanges(data.eventsToSubscribeOnOfferChanges);
    }

    if (message === 'CURSOR_OFFER_UPDATE') {
      const data = setupOffer(rawData, isLive.value);

      cursorId.value = data.cursorId;

      const eventsCopy = new Map(events.value);

      updateOfferEntity(sports, data.sports);
      updateOfferEntity(categories, data.categories);
      updateOfferEntity(tournaments, data.tournaments);
      updateOfferEntity(tournamentRounds, data.tournamentRounds);
      updateOfferEntity(marketsPerSport, data.marketsPerSport);
      updateOfferEntity(markets, data.markets);
      updateOfferEntity(outcomes, data.outcomes);
      updateOfferEntity(events, data.events);

      if (data.promoOffers) {
        updateOfferEntity(promoOffer, data.promoOffers);
        promoOfferList.value = [...promoOfferList.value, ...data.promoOfferList];
      }

      if (data.catalogs) {
        updateOfferEntity(catalogs, data.catalogs);
        catalogList.value = [...catalogList.value, ...data.catalogList];
      }

      const sortedEvents = sortEvents(
        data.eventsList,
        events.value,
        sports.value,
        categories.value,
        tournaments.value,
        tournamentRounds.value,
        isLive.value,
        promoOffer.value,
        catalogs.value,
      );

      for (const event of sortedEvents) {
        if (!eventsCopy.has(event)) eventsList.value.push(event);
      }

      subscribeEventsOnMetadataChanges(
        isLive.value
          ? data.eventsList
          : data.eventsList?.filter((id) => data.events.get(id).playStatus === 2),
      );
      subscribeEventsOnOfferChanges(data.eventsToSubscribeOnOfferChanges);
    }

    if (message === 'SETUP_TOURNAMENT_SWAP') {
      const data = setupOffer(rawData, isLive);

      for (const [id, collection] of data?.marketsPerSport ?? []) {
        if (marketsPerSport.value.has(id)) continue;

        marketsPerSport.value.set(id, collection);
      }

      updateOfferEntity(markets, data.markets);
      updateOfferEntity(outcomes, data.outcomes);
      updateOfferEntity(events, data.events);
      eventviewTournamentSwapEventsList.value = sortEvents(
        data?.eventsList ?? [],
        events.value,
        sports.value,
        categories.value,
        tournaments.value,
        tournamentRounds.value,
        isLive.value,
      );

      subscribeEventsOnMetadataChanges(
        isLive.value
          ? data.eventsList
          : data.eventsList?.filter((id) => data.events.get(id).playStatus === 2),
      );
      subscribeEventsOnOfferChanges(data.eventsToSubscribeOnOfferChanges);
    }

    if (message === 'FULL_ODDS_UPDATE') {
      for (const event of rawData) {
        for (const collection of [events, promotedEvents, eventviewEvent]) {
          const eventInState = collection.value?.size
            ? collection.value.get(event.id)
            : collection.value?.id === event.id && collection.value;
          if (!eventInState) continue;

          const eventMarkets = new Map();
          for (const market of event.markets) {
            const marketInState =
              (eventInState.markets.has(market.id) && eventInState.markets.get(market.id)) ?? null;

            const eventMarketOutcomes = mapEventMarketOutcomes(
              market.outcomes.reduce((acc, outcome) => {
                const outcomeInState =
                  (marketInState &&
                    marketInState.outcomes.has(outcome.id) &&
                    marketInState.outcomes.get(outcome.id)) ||
                  {};

                acc.push({
                  ...outcomeInState,
                  ...outcome,
                });
                return acc;
              }, []),
            );

            eventMarkets.set(market.id, {
              ...(marketInState ?? {}),
              ...market,
              outcomes: eventMarketOutcomes,
            });
          }

          const dataToUpdate = {
            ...eventInState,
            ...event,
            markets: eventMarkets,
          };
          if (collection.value.size) collection.value.set(event.id, dataToUpdate);
          else collection.value = dataToUpdate;
        }

        for (const selectionId of betslipStore.selectionsList) {
          const [eventId] = selectionId.split('/');
          if (event.id !== eventId) continue;

          const selection = betslipStore.selectionsData[selectionId];

          for (const market of event.markets) {
            if (selection.marketId !== market.id)
              betslipStore.selectionsData[selectionId].limits.market = true;
            else delete betslipStore.selectionsData[selectionId].limits.market;

            for (const outcome of market.outcomes) {
              if (selection.outcomeId !== outcome.id)
                betslipStore.selectionsData[selectionId].limits.outcome = true;
              else delete betslipStore.selectionsData[selectionId].limits.outcome;
            }
          }
        }
      }
    }

    if (message === 'MARKET_GROUPS_UPDATE') {
      for (const group of rawData) {
        eventviewMetadataRef.get(group.sportId).marketGroups.set(group.id, group);
        eventviewMarketGroups.value.set(group.id, group);
      }
    }

    if (message === 'MARKET_GROUPS_REMOVE') {
      for (const id of rawData) {
        if (eventviewMarketGroups.value.has(id)) eventviewMarketGroups.value.delete(id);

        for (const [sportId, metaPerSport] of eventviewMetadataRef)
          for (const group of metaPerSport.marketGroups) {
            if (group.id === id) eventviewMetadataRef.get(sportId).marketGroups.delete(id);
          }
      }
    }

    if (message === 'MARKETS_UPDATE') {
      const data = mapMarkets(rawData);
      updateOfferEntity(markets, data.markets);
      updateOfferEntity(outcomes, data.outcomes);

      for (const [, market] of data.markets) {
        if (eventviewMetadataRef?.get(market.sportId))
          eventviewMetadataRef.get(market.sportId).markets.set(market.id, market);

        if (eventviewId.value && eventviewEvent.value?.sportId === market.sportId) {
          eventviewMarkets.value.set(market.id, market);
        }
      }

      for (const [, outcome] of data.outcomes) {
        const sportId = data.markets.get(outcome.marketId).sportId;
        if (eventviewMetadataRef?.get(sportId))
          eventviewMetadataRef.get(sportId).outcomes.set(outcome.id, outcome);

        if (eventviewId.value && eventviewEvent.value?.sportId === sportId) {
          eventviewOutcomes.value.set(outcome.id, outcome);
        }
      }
    }

    cb();
  }

  offerWorker.onmessage = ({ data: message }) => {
    const { event, data = {} } = message;

    if (event === 'bet-builder-selections') {
      betBuilderSelections.value.set(data.eventId, data.selections);
    }

    if (event === 'update-bet-builder-selections') {
      for (const [marketId, market] of betBuilderSelections.value.get(data.eventId)) {
        for (const [outcomeId] of market.outcomes) {
          betBuilderSelections.value
            .get(data.eventId)
            .get(marketId)
            .outcomes.get(outcomeId).available = data.selections
            ?.get(marketId)
            ?.outcomes?.get(outcomeId)?.available;
        }
      }
    }

    if (event === 'set-stored-bet') {
      betslipStore.selectedTicketType = data.selectedTicketType;
      betslipStore.selectionsData = data.selectionsData;
      betslipStore.selectionsList = data.selectionsList;
      betslipStore.systems = data.systems;

      for (const [eventId, selections] of data.betBuilderSelectionsPerEvent) {
        betBuilderSelections.value.set(eventId, selections);
        if (data.selections) {
          sendOfferWorkerMessage('update-event-bet-builder-selections', {
            eventId,
            selections: data.selections,
          });
        }
      }

      calculatePayments();
    }

    if (event === 'update-markets-in-state') {
      const eventviewMetadata = eventviewMetadataRef.get(eventviewEvent.value.sportId);

      for (const [id, market] of data.markets) {
        const inStateMarket = markets.value.get(id);
        markets.value.set(id, { ...inStateMarket, ...market });

        if (eventviewMetadata?.markets?.has(id))
          eventviewMetadata.markets.set(id, { ...inStateMarket, ...market });
      }

      for (const [id, outcome] of data.outcomes) {
        const inStateOutcome = outcomes.value.get(id);
        outcomes.value.set(id, { ...inStateOutcome, ...outcome });

        if (eventviewMetadata?.outcomes?.has(id))
          eventviewMetadata.outcomes.set(id, { ...inStateOutcome, ...outcome });
      }
    }

    if (event === 'update-events-in-state') {
      if (!data.events || !data.events?.size) return;

      updateOfferEntity(markets, data.markets);
      updateOfferEntity(outcomes, data.outcomes);
      updateOfferEntity(marketsPerSport, data.marketsPerSport);
      updateOfferEntity(sports, data.sportsToAdd);
      updateOfferEntity(categories, data.categoriesToAdd);
      updateOfferEntity(tournaments, data.tournamentsToAdd);
      updateOfferEntity(tournamentRounds, data.tournamentRoundsToAdd);

      let eventsToSubscribeOnMetadata = [];

      for (const [id, event] of data.events) {
        if (
          !checkEventFitmentInOffer({
            eventsList: eventsList.value,
            isLive: isLive.value,
            timeFilters: timeFilters.value,
            selectedFilters: selectedFiltersExternal.value,
            event,
          })
        )
          continue;

        const eventsCopy = new Map([...(events.value.size ? events.value : []), [event.id, event]]);
        const sortedEvents = sortEvents(
          [...eventsList.value, event.id],
          eventsCopy,
          sports.value,
          categories.value,
          tournaments.value,
          tournamentRounds.value,
          isLive.value,
        );

        const eventIdx = sortedEvents.findIndex((id) => event.id === id);
        const tournamentLoaded = eventsList.value.some(
          (id) => events.value.get(id).tournamentId === event.tournamentId,
        );
        if ((!tournamentLoaded && sortedEvents.pop() === event.id) || eventIdx === -1) continue;

        events.value.set(id, event);
        eventsList.value.splice(eventIdx, 0, id);

        if (event.playStatus === 2 || isLive.value) eventsToSubscribeOnMetadata.push(event.id);
      }

      subscribeEventsOnMetadataChanges(eventsToSubscribeOnMetadata);
      subscribeEventsOnOfferChanges(data.eventsToSubscribeOnOfferChanges);
    }
  };

  const selectedPromoOffer = computed(() => {
    return selectedFiltersExternal.value.promotedOffer?.id ?? routeParams.value.promotedOffer;
  });

  const selectedPromoCatalog = computed(() => {
    return selectedFiltersExternal.value.catalog?.id ?? routeParams.value.catalog;
  });

  function getPromoIcon({ code }, isLg = false) {
    if (!code) return '';

    if (code.startsWith('flag')) {
      const [, flag] = code.split('-');

      return `flag ${isLg ? `flag-lg flag-lg-${flag}` : code}`;
    }

    return code;
  }

  const availableOfferViews = computed(() => {
    return (
      (isMobile
        ? config.value.availableMobileOfferViews
        : config.value.availableDesktopOfferViews) ??
      new Map([['standard', ['horizontal', 'single']]])
    );
  });

  const offerGridLayouts = computed(() => {
    return availableOfferViews.value.get(offerView.value) ?? [];
  });

  function changeOfferGridLayout() {
    let selectedGridModeIndex = offerGridLayouts.value.indexOf(offerGridLayout.value) ?? 0;

    selectedGridModeIndex = selectedGridModeIndex === 0 ? 1 : 0;

    updatePunterPreferences({
      key: isMobile ? 'offerGridMobileLayout' : 'offerGridDesktopLayout',
      value: offerGridLayouts.value[selectedGridModeIndex],
    });
  }

  const betAssistCache = new Map();

  return {
    // Router
    route,
    isLive,
    routeParams,

    // Local config
    config,
    setConfig,

    // Translations
    translations,
    setTranslations,
    getTranslation,

    // Punter
    punterData,
    punterSelectedTheme,
    punterLoggedIn,

    // Punter Wallet
    punterWallet,
    updatePunterWallet,

    // Offer filters
    filterCursorId,
    filterCategories,
    filterSports,
    filterTournaments,
    expandedSport,
    expandedCategory,
    expandedPromoOffer,
    resetFilterData,
    setFilterData,

    // loading variables
    loading,
    setLoading,

    // timefilters
    timeFilters,

    // App. filters
    selectedFiltersExternal,
    setSelectedFiltersExternal,

    // Search term
    searchTerm,
    setSearchTerm,
    searchToggled,

    // Fav events
    numberOfFavouriteEvents,
    setNumberOfFavouriteEvents,

    // Eventview
    eventviewId,
    eventviewEvent,
    eventviewTournamentSwapEventsList,
    eventviewMetadataRef,
    eventviewMarkets,
    eventviewOutcomes,
    eventviewMarketGroups,
    eventviewMarketToFocus,
    selectedMarketGroup,
    selectedMoreLabelMarketGroup,

    // Bet assist
    betAssistCache,

    // Selected view
    selectedView,
    selectedSportInSearch,
    setSelectedView,

    // Bet preview
    betPreviewDisplayed,
    toggleBetPreview,

    // Active ticket tab
    activeTicketTab,
    setActiveTicketTab,

    // Notifications
    notifications,
    addNotification,
    removeNotification,

    // Num. of markets to display
    displayedMarketsNumber,

    promotedEvents,
    promotedEventsList,

    // Currency
    currency,
    currencySymbol,

    // Punter preferences
    punterPreferences,
    updatePunterPreferences,

    // Number of live events
    numberOfLiveEvents,
    liveEventsStatsCursor,
    setNumberOfLiveEvents,
    createLiveEventsCountPeriodicUpdate,

    // Offer worker
    sendOfferWorkerMessage,
    updateEventMetadata,

    // Offer
    cursorId,
    sports,
    sportsList,
    categories,
    tournaments,
    tournamentRounds,
    markets,
    marketsPerSport,
    outcomes,
    events,
    eventsList,

    updateOfferEntity,
    resetOffer,
    unsubscribeEventsFromEventchanges,

    // Selected market
    selectedMarket,

    // Bet builder
    betBuilderSelections,
    resetBetBuilderSelections,
    getEventSelectionsForCalculation,
    customBetsLoading,
    loadCustomBets,

    // Last bets
    setBetDetails,
    updateCashoutBets,
    loadBetList,
    openDynamicBetDetails,
    fetchCashoutBets,
    forceCashoutCalculation,
    cashOutBet,
    loadPunterBets,
    addPlacedBetsToBetList,
    mapBetDetailsSelections,

    punterBetsLoading,
    lastBets,
    cashoutBets,
    betDetailsSelections,
    betDetails,

    getAvailableEvents,

    // Rebet
    rebet,
    displayRebetUnavailableMessage,
    subscribeBetEvents,

    useOfferAdapter,

    // Odd format
    oddFormat,
    availableOddFormats,

    // Offer view
    offerView,
    availableOfferViews,
    offerGridLayout,
    offerGridLayouts,
    changeOfferGridLayout,

    // Betslip bar
    betslipBarFixedToBottom,
    fixBetslipBarToBottom,

    resetCompleteOfferState,

    // promo offer
    promoFilterCursorId,
    promoFilterOffers,
    promoFilterCatalogs,
    setPromoOfferFilterData,
    resetPromoOfferFilterData,
    promoOffer,
    promoOfferList,
    catalogs,
    catalogList,
    selectedPromoOffer,
    selectedPromoCatalog,
    getPromoIcon,
  };
});
